"use client"

import styled from "@emotion/styled"
import { useInView } from "react-intersection-observer"
import colors from "theme/colors"
import {
  Container,
  NewText,
  transitionDelayTime,
  TransitionUp,
  useInViewConfig,
  useTransitionUpStyles,
} from "ui"
import { delayTime, styleObjectToCss } from "ui/transitions/utils"
import { extractProperty } from "utils"

const StyledContainer = styled.div`
  padding: 56px 0px;
  ${(props) => props.theme.breakpoints.up("md")} {
    padding: 112px 0px;
  }
`
const StyledTextContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 40px;
  ${(props) => props.theme.breakpoints.up("md")} {
    grid-template-columns: 1fr 1fr;
    gap: 80px;
  }
`
const StyledNumbersContainer = styled.div`
  margin-top: 40px;
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px;
  ${(props) => props.theme.breakpoints.up("md")} {
    margin-top: 80px;

    gap: 20.5px;
    grid-template-columns: repeat(3, 1fr);
  }
`

const StyledNumberContainer = styled.div`
  padding: 24px;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.08);
  border: 2px solid var(--DarkGreen, #5b7553);
  border-radius: 12px;
`
type Props = {
  data: any
}

function PowerNumbers({ data }: Props) {
  const title = extractProperty(data, "title")
  const description = extractProperty(data, "shortDescription")
  const numbers = extractProperty(data, "numbers")
  const { ref, inView } = useInView(useInViewConfig)

  const imageTransitionStyles = useTransitionUpStyles(inView)
  const descriptionTransitionStyles = useTransitionUpStyles(inView, delayTime)
  const { ref: mobileRef, inView: mobileInView } = useInView(useInViewConfig)

  return (
    <Container>
      <StyledContainer ref={ref}>
        <StyledTextContainer>
          <NewText
            cssString={styleObjectToCss(imageTransitionStyles)}
            component={"h2"}
            desktopFontSize={40}
            desktopLineHeight={"44px"}
            desktopFontWeight={400}
            mobileFontSize={32}
            mobileLineHeight={"46px"}
            mobileFontWeight={400}
            color={colors.black}
          >
            {title}
          </NewText>
          <NewText
            cssString={styleObjectToCss(descriptionTransitionStyles)}
            component={"p"}
            desktopFontSize={16}
            desktopLineHeight={"23px"}
            desktopFontWeight={400}
            mobileFontSize={14}
            mobileLineHeight={"20px"}
            mobileFontWeight={400}
            color={colors.black}
          >
            {description}
          </NewText>
        </StyledTextContainer>
        <StyledNumbersContainer ref={mobileRef}>
          {numbers.map((number: any, index: number) => {
            const label = extractProperty(number, "label")
            const nuber = extractProperty(number, "nuber")
            return (
              <TransitionUp
                key={index.toString()}
                $inView={mobileInView}
                $delay={transitionDelayTime * 0.5 * index}
              >
                <StyledNumberContainer key={index.toString()}>
                  <NewText
                    component={"h3"}
                    desktopFontSize={14}
                    desktopLineHeight={"20px"}
                    desktopFontWeight={600}
                    color={colors.black}
                  >
                    {label}
                  </NewText>
                  <NewText
                    component={"p"}
                    desktopFontSize={56}
                    desktopLineHeight={"81px"}
                    desktopFontWeight={600}
                    color={colors.darkGreen}
                  >
                    {nuber}
                  </NewText>
                </StyledNumberContainer>
              </TransitionUp>
            )
          })}
        </StyledNumbersContainer>
      </StyledContainer>
    </Container>
  )
}

export default PowerNumbers
