"use client"

import styled from "@emotion/styled"

import { GetFootersQuery } from "apollo"
import Link from "next/link"
import { usePathname } from "next/navigation"
import { Container, Desktop, LinkedinIcon, Mobile, NewText, useIsDesktop } from "ui"
import ImageWithAlt from "ui/ImageWithAlt"
import Env from "ui/layout/Footer/Env"
import { sharedColors } from "ui/theme/colors"
import { extractImage, footerMenuClick, getUmbracoPDFUrl } from "utils"
import { extractProperty } from "utils/extractProperty"
import { getNonUmbracoUrl } from "./getNonUmbracoUrl"

const StyledFooter = styled.footer`
  width: 100%;
  background-color: ${(props) => props.theme.colors.bonitaFooter};
`

const StyledList = styled.div`
  display: flex;

  padding-top: 32px;
  /* align-items: flex-start; */

  flex-direction: column;
  ${(props) => props.theme.breakpoints.up("md")} {
    list-style-type: none;
    gap: 32px;
    padding: 60px 0px 0px 0px;
    align-items: center;
  }
`
const StyledListItems = styled.div`
  display: flex;
  width: 100%;

  padding-bottom: 16px;
  flex-direction: column;
  list-style-type: none;
  justify-content: flex-start;

  ${(props) => props.theme.breakpoints.up("md")} {
    flex-direction: row;
    justify-content: center;
    padding-bottom: 24px;

    gap: 32px;
  }
`

const StyledLast = styled.div`
  width: 100%;
  border-bottom: 1px solid #a2c900;
`

const StyledBottomDiv = styled.div`
  background-color: ${sharedColors?.bonitaFooter};
  padding-bottom: 24px;
`

const StyledCopyContainer = styled.div`
  display: flex;
  list-style-type: none;
  margin-top: 32px;
  gap: 24px;
  align-items: center;
  justify-content: center;
`
const StyledMobileContainer = styled.div`
  display: flex;
  height: 20px;
  gap: 12px;
  margin-bottom: 24px;

  ${(props) => props.theme.breakpoints.up("md")} {
    gap: 24px;
    margin-bottom: 0;
  }
  a {
    padding-right: 12px;
    border-right: 1px solid white;
    ${(props) => props.theme.breakpoints.up("md")} {
      padding-right: 0px;
      border: none;
    }
  }
  a:last-child {
    border-right: none;
  }
`
const StyledLinkK = styled(Link)<{ isLinkedin: boolean }>`
  border-bottom: ${(props) => (props.isLinkedin ? "none" : "1px solid white")};
  padding: 8px 0;
  ${(props) => props.theme.breakpoints.up("md")} {
    border: none;
  }
`

const NewTextWrapper = styled.div`
  display: inline-block;
  width: 100%;
`

const StyledLogoContainer = styled.div`
  width: 78.6px;
  height: 36px;
  position: relative;
`

const StyledLinkedin = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

interface Props {
  data: GetFootersQuery
  companyName: string
}

function TierraFooter({ data }: Props) {
  const pathname = usePathname()
  const desktopFooterData = data?.footer_desktop
  const mobileFooterData = data?.footer_mobile

  const footerBottomLinksDesktop = extractProperty(desktopFooterData, "bottomLinks")
  const footerBottomLinksMobile = extractProperty(mobileFooterData, "bottomLinks")
  const isDesktop = useIsDesktop()

  const desktopUrls = extractProperty(footerBottomLinksDesktop[0], "links") || []
  const mobileUrls = extractProperty(footerBottomLinksMobile[0], "links") || []

  const desktopItems = extractProperty(desktopFooterData, "lists")
  const mobileItems = extractProperty(mobileFooterData, "lists")

  const logo = extractImage(desktopFooterData, "logo")

  const text = extractProperty(footerBottomLinksDesktop[0], "text")

  return (
    <StyledFooter role="contentinfo">
      <Container>
        <StyledList>
          {isDesktop ? (
            <StyledLogoContainer>
              <ImageWithAlt image={logo} fill />
            </StyledLogoContainer>
          ) : null}
          <Desktop>
            <StyledListItems>
              {desktopItems.map((main: any, inda: number) => {
                const children = extractProperty(main, "urls")
                return children.map((item: any, ind: number) => {
                  const link = extractProperty(item, "link")
                  const linkTitle = extractProperty(item, "linkTitle")

                  const decodedPathname =
                    decodeURI(pathname) === "/" ? decodeURI(pathname) : decodeURI(pathname) + "/"

                  return (
                    <StyledLinkK
                      style={{
                        marginTop: !isDesktop && inda === desktopItems.length - 1 ? "16px" : "0px",
                      }}
                      isLinkedin={linkTitle === "linkedin"}
                      key={ind.toString()}
                      href={getNonUmbracoUrl(link?.[0]?.url) || "/"}
                      onClick={() => footerMenuClick(linkTitle, "")}
                    >
                      <NewTextWrapper>
                        {linkTitle === "linkedin" ? (
                          <StyledLinkedin>
                            {!isDesktop && (
                              <StyledLogoContainer>
                                <ImageWithAlt image={logo} fill />
                              </StyledLogoContainer>
                            )}
                            <LinkedinIcon />
                          </StyledLinkedin>
                        ) : (
                          <NewText
                            key={ind}
                            desktopFontSize={16}
                            component="li"
                            desktopLineHeight="23px"
                            desktopFontWeight={
                              decodedPathname === getNonUmbracoUrl(link?.[0]?.url) ? "700" : "400"
                            }
                            color={sharedColors.white}
                          >
                            {linkTitle}
                          </NewText>
                        )}
                      </NewTextWrapper>
                    </StyledLinkK>
                  )
                })
              })}
            </StyledListItems>
          </Desktop>
          <Mobile>
            <StyledListItems>
              {mobileItems.map((main: any, inda: number) => {
                const children = extractProperty(main, "urls")
                return children.map((item: any, ind: number) => {
                  const link = extractProperty(item, "link")
                  const linkTitle = extractProperty(item, "linkTitle")

                  const decodedPathname =
                    decodeURI(pathname) === "/" ? decodeURI(pathname) : decodeURI(pathname) + "/"

                  return (
                    <StyledLinkK
                      style={{
                        marginTop: !isDesktop && inda === mobileItems.length - 1 ? "16px" : "0px",
                      }}
                      isLinkedin={linkTitle === "linkedin"}
                      key={ind.toString()}
                      href={getNonUmbracoUrl(link?.[0]?.url) || "/"}
                      onClick={() => footerMenuClick(linkTitle, "")}
                    >
                      <NewTextWrapper>
                        {linkTitle === "linkedin" ? (
                          <StyledLinkedin>
                            {!isDesktop && (
                              <StyledLogoContainer>
                                <ImageWithAlt image={logo} fill />
                              </StyledLogoContainer>
                            )}
                            <LinkedinIcon />
                          </StyledLinkedin>
                        ) : (
                          <NewText
                            key={ind}
                            desktopFontSize={16}
                            component="li"
                            desktopLineHeight="23px"
                            desktopFontWeight={
                              decodedPathname === getNonUmbracoUrl(link?.[0]?.url) ? "700" : "400"
                            }
                            color={sharedColors.white}
                          >
                            {linkTitle}
                          </NewText>
                        )}
                      </NewTextWrapper>
                    </StyledLinkK>
                  )
                })
              })}
            </StyledListItems>
          </Mobile>
        </StyledList>
      </Container>
      <StyledBottomDiv>
        <Container>
          <StyledLast />

          <StyledCopyContainer>
            {isDesktop && (
              <NewText
                desktopFontSize={14}
                color={sharedColors.white}
                component="p"
                mobileFontSize={12}
              >
                {text}
              </NewText>
            )}
            <Desktop>
              <StyledMobileContainer>
                {desktopUrls?.map((item: any, ind: number) => {
                  return (
                    <a
                      target="_blank"
                      key={ind.toString()}
                      href={getUmbracoPDFUrl(item?.url) || "#"}
                    >
                      <NewText
                        style={{ textDecoration: "underline" }}
                        desktopFontSize={14}
                        component="p"
                        desktopLineHeight="20px"
                        desktopFontWeight={"400"}
                        color={sharedColors.white}
                      >
                        {item?.name}
                      </NewText>
                    </a>
                  )
                })}
              </StyledMobileContainer>
            </Desktop>
            <Mobile>
              <StyledMobileContainer>
                {mobileUrls?.map((item: any, ind: number) => {
                  return (
                    <a
                      target="_blank"
                      key={ind.toString()}
                      href={getUmbracoPDFUrl(item?.url) || "#"}
                    >
                      <NewText
                        style={{ textDecoration: "underline" }}
                        desktopFontSize={14}
                        component="p"
                        desktopLineHeight="20px"
                        desktopFontWeight={"400"}
                        color={sharedColors.white}
                      >
                        {item?.name}
                      </NewText>
                    </a>
                  )
                })}
              </StyledMobileContainer>
            </Mobile>
          </StyledCopyContainer>
          {!isDesktop && (
            <NewText
              textAlign={"center"}
              desktopFontSize={14}
              color={sharedColors.white}
              component="p"
              mobileFontSize={12}
            >
              {text}
            </NewText>
          )}
          <Env />
        </Container>
      </StyledBottomDiv>
    </StyledFooter>
  )
}

export default TierraFooter
