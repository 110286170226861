"use client"

import styled from "@emotion/styled"
import { AdvancedMarker, APIProvider, Map, Pin } from "@vis.gl/react-google-maps"
import { useLocale } from "next-intl"
import { useInView } from "react-intersection-observer"
import colors from "theme/colors"
import {
  BonitaLocationIcon,
  Container,
  NewText,
  transitionDelayTime,
  TransitionUp,
  useInViewConfig,
  useTransitionUpStyles,
} from "ui"
import { delayTime, styleObjectToCss } from "ui/transitions/utils"
import { extractProperty } from "utils"

const StyledMapContainer = styled.div`
  height: 300px;
  width: 100%;
  border-radius: 12px;
  overflow: hidden;
`

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;
  padding-bottom: 56px;
  ${(props) => props.theme.breakpoints.up("md")} {
    gap: 64px;
    padding-bottom: 112px;
  }
`

const StyledLocationDetails = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-top: 16px;
  ${(props) => props.theme.breakpoints.up("md")} {
    margin-top: 32px;
  }
`

const StyledTextsContainer = styled.div``

const MAPID = "40df340c1a7a4582"

type Props = { data: any }

function BonitaMap({ data }: Props) {
  const locale = useLocale()
  const title = extractProperty(data, "title")
  const shortDescription = extractProperty(data, "shortDescription")
  const { address, mapConfig } = extractProperty(data, "map")

  const centerCoordinates = mapConfig?.centerCoordinates
    ? {
        lat: mapConfig.centerCoordinates.latitude,
        lng: mapConfig.centerCoordinates.longitude,
      }
    : undefined

  const { ref, inView } = useInView(useInViewConfig)

  const transitionDelay = transitionDelayTime * 0.5
  const imageTransitionStyles = useTransitionUpStyles(inView)
  const descriptionTransitionStyles = useTransitionUpStyles(inView, delayTime)
  if (!mapConfig?.apiKey) return null
  return (
    <Container>
      <StyledContainer>
        <StyledTextsContainer>
          <NewText
            cssString={styleObjectToCss(imageTransitionStyles)}
            component={"h2"}
            desktopFontSize={40}
            desktopLineHeight={"44px"}
            desktopFontWeight={400}
            mobileFontSize={32}
            mobileLineHeight={"46px"}
            mobileFontWeight={400}
            color={colors.black}
          >
            {title}
          </NewText>
          <NewText
            cssString={styleObjectToCss(descriptionTransitionStyles)}
            marginTop="24px"
            component={"p"}
            desktopFontSize={16}
            desktopLineHeight={"23px"}
            desktopFontWeight={400}
            mobileFontSize={14}
            mobileLineHeight={"20px"}
            mobileFontWeight={400}
            color={colors.black}
          >
            {shortDescription}
          </NewText>
          <TransitionUp $inView={inView} $delay={transitionDelay}>
            <StyledLocationDetails>
              <BonitaLocationIcon />
              <NewText
                component={"p"}
                desktopFontSize={16}
                desktopLineHeight={"23px"}
                desktopFontWeight={700}
                mobileFontSize={14}
                mobileLineHeight={"20px"}
                mobileFontWeight={400}
                color={colors.darkGreen}
              >
                {address?.fullAddress}
              </NewText>
            </StyledLocationDetails>
          </TransitionUp>
        </StyledTextsContainer>
        <APIProvider apiKey={mapConfig.apiKey} language={locale === "he" ? "iw" : "en"}>
          <TransitionUp $inView={inView} $delay={transitionDelay}>
            <StyledMapContainer ref={ref}>
              <Map
                defaultZoom={12}
                defaultCenter={centerCoordinates}
                mapId={MAPID}
                disableDefaultUI={false}
              >
                <AdvancedMarker
                  position={{
                    lat: address?.coordinates?.latitude,
                    lng: address?.coordinates?.longitude,
                  }}
                >
                  <Pin
                    // background="grey"
                    // glyphColor={"purple"}
                    borderColor={"green"}
                  />
                </AdvancedMarker>
              </Map>
            </StyledMapContainer>
          </TransitionUp>
        </APIProvider>
      </StyledContainer>
    </Container>
  )
}

export default BonitaMap
