"use client"

import styled from "@emotion/styled"
import { Button } from "@mui/material"
import { useRef, useState } from "react"
import { useInView } from "react-intersection-observer"
import colors from "theme/colors"
import { Container, NewText, useInViewConfig, useTransitionUpStyles } from "ui"
import VideoPauseIcon from "ui/icons/video/VideoPauseIcon"
import VideoPlayIcon from "ui/icons/video/VideoPlayIcon"
import ImageWithAlt from "ui/ImageWithAlt"
import { delayTime, styleObjectToCss } from "ui/transitions/utils"
import { extractImage, extractProperty } from "utils"

const StyledTextContainer = styled.div`
  max-width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  ${(props) => props.theme.breakpoints.up("md")} {
    max-width: 511px;
  }
`

const StyledContainer = styled.div<{ noBottomPadding?: boolean }>`
  padding: 56px 0px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 40px;
  ${(props) => props.theme.breakpoints.up("md")} {
    padding: 112px 0px;
    align-items: center;
    padding-bottom: ${(props) => (props.noBottomPadding ? "0px" : "112px")};
    flex-direction: row;
    gap: 80px;
  }
`

const StyledImageContainer = styled.div<{ isVideo: boolean }>`
  position: relative;
  width: calc(100vw - 32px);
  height: 420px;
  border-radius: 12px;
  overflow: hidden;
  ${(props) => props.theme.breakpoints.up("md")} {
    width: 424px;
    height: ${(props) => (props.isVideo ? "640px" : "420px")};
  }
`

const StyledButton = styled(Button)`
  position: absolute;
  bottom: 0px;
  right: 0px;
  z-index: 10;
`

const StyledContentWrapper = styled.div`
  li {
    margin-bottom: 24px;
    padding-left: 8px;
    ${(props) => props.theme.breakpoints.up("md")} {
      padding-left: 16px;
    }
  }

  ul {
    padding-left: 24px;
    list-style: url("/listStyle.svg");
  }
`

type Props = {
  data: any
  noBottomPadding?: boolean
}

function ImageTextBlock({ data, noBottomPadding }: Props) {
  const title = extractProperty(data, "blockTitle")
  const content = extractProperty(data, "blockContent")
  const image = extractImage(data, "imageForDesktop")
  const videoRef = useRef<HTMLVideoElement>(null)
  const videoRefMobile = useRef<HTMLVideoElement>(null)
  const [isPlaying, setIsPlaying] = useState(false)
  const handlePlay = () => {
    if (videoRef.current) {
      videoRef.current.play()
      setIsPlaying(true)
    }
    if (videoRefMobile.current) {
      videoRefMobile.current.play()
      setIsPlaying(true)
    }
  }

  const handlePause = () => {
    if (videoRef.current) {
      videoRef.current.pause()
      setIsPlaying(false)
    }
    if (videoRefMobile.current) {
      videoRefMobile.current.pause()
      setIsPlaying(false)
    }
  }

  const { ref, inView } = useInView(useInViewConfig)

  const imageTransitionStyles = useTransitionUpStyles(inView)
  const descriptionTransitionStyles = useTransitionUpStyles(inView, delayTime)
  return (
    <Container>
      <StyledContainer noBottomPadding={noBottomPadding} ref={ref}>
        <StyledTextContainer>
          <NewText
            cssString={styleObjectToCss(imageTransitionStyles)}
            component={"h2"}
            desktopFontSize={40}
            desktopLineHeight={"44px"}
            desktopFontWeight={400}
            mobileFontSize={32}
            mobileLineHeight={"46px"}
            mobileFontWeight={400}
            color={colors.black}
          >
            {title}
          </NewText>
          <StyledContentWrapper>
            <NewText
              cssString={styleObjectToCss(descriptionTransitionStyles)}
              marginTop={"24px"}
              component={"div"}
              desktopFontSize={14}
              desktopLineHeight={"20px"}
              desktopFontWeight={400}
              mobileFontSize={12}
              mobileLineHeight={"17px"}
              mobileFontWeight={400}
              color={colors.black}
              dangerouslySetInnerHTML={{ __html: content }}
            />
          </StyledContentWrapper>
        </StyledTextContainer>
        <StyledImageContainer isVideo={image?.url?.endsWith("mp4")}>
          {image?.url?.endsWith("mp4") ? (
            <video
              ref={videoRefMobile}
              preload="auto"
              autoPlay
              loop
              muted
              playsInline
              onLoadedData={() => videoRefMobile.current?.play()}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                ...imageTransitionStyles,
              }}
            >
              <source src={image?.url} type="video/mp4" />
            </video>
          ) : (
            <ImageWithAlt image={image} fill style={{ ...imageTransitionStyles }} />
          )}
          {image?.url?.endsWith("mp4") ? (
            <StyledButton onClick={isPlaying ? handlePause : handlePlay}>
              {isPlaying ? <VideoPauseIcon /> : <VideoPlayIcon />}
            </StyledButton>
          ) : null}
        </StyledImageContainer>
      </StyledContainer>
    </Container>
  )
}

export default ImageTextBlock
