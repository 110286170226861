import(/* webpackMode: "eager" */ "/app/apps/tierra-bonita/theme/ThemeProviders.tsx");
import(/* webpackMode: "eager" */ "/app/apps/tierra-bonita/tierraLayout/TierraLayout.tsx");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@apollo+experimental-nextjs-app-support@0.6.0_@apollo+client@3.8.8_next@14.1.0_react@18.2.0/node_modules/@apollo/experimental-nextjs-app-support/dist/ssr/ApolloNextAppProvider.js");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@apollo+experimental-nextjs-app-support@0.6.0_@apollo+client@3.8.8_next@14.1.0_react@18.2.0/node_modules/@apollo/experimental-nextjs-app-support/dist/ssr/hooks.js");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next-intl@3.5.3_next@14.1.0_react@18.2.0/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.1.0_@babel+core@7.23.7_react-dom@18.2.0_react@18.2.0/node_modules/next/font/local/target.css?{\"path\":\"app/[locale]/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../../public/fonts/rag/RagSans-1.4-Regular.woff2\",\"weight\":\"400\"},{\"path\":\"../../public/fonts/rag/RagSans-1.4-Regular.woff\",\"weight\":\"400\"},{\"path\":\"../../public/fonts/rag/RagSans-1.4-Medium.woff2\",\"weight\":\"500\"},{\"path\":\"../../public/fonts/rag/RagSans-1.4-Medium.woff\",\"weight\":\"500\"},{\"path\":\"../../public/fonts/rag/RagSans-1.4-SemiBold.woff2\",\"weight\":\"600\"},{\"path\":\"../../public/fonts/rag/RagSans-1.4-SemiBold.woff\",\"weight\":\"600\"},{\"path\":\"../../public/fonts/rag/RagSans-1.4-Bold.woff2\",\"weight\":\"700\"},{\"path\":\"../../public/fonts/rag/RagSans-1.4-Bold.woff\",\"weight\":\"700\"},{\"path\":\"../../public/fonts/rag/RagSans-1.4-ExtraBold.woff2\",\"weight\":\"800\"},{\"path\":\"../../public/fonts/rag/RagSans-1.4-ExtraBold.woff\",\"weight\":\"800\"},{\"path\":\"../../public/fonts/rag/RagSans-1.4-Black.woff2\",\"weight\":\"900\"},{\"path\":\"../../public/fonts/rag/RagSans-1.4-Black.woff\",\"weight\":\"900\"},{\"path\":\"../../public/fonts/rag/RagSans-1.4-ExtraLight.woff2\",\"weight\":\"200\"},{\"path\":\"../../public/fonts/rag/RagSans-1.4-ExtraLight.woff\",\"weight\":\"200\"},{\"path\":\"../../public/fonts/rag/RagSans-1.4-Light.woff2\",\"weight\":\"300\"},{\"path\":\"../../public/fonts/rag/RagSans-1.4-Light.woff\",\"weight\":\"300\"},{\"path\":\"../../public/fonts/rag/RagSans-1.4-Thin.woff2\",\"weight\":\"100\"},{\"path\":\"../../public/fonts/rag/RagSans-1.4-Thin.woff\",\"weight\":\"100\"}],\"display\":\"swap\",\"variable\":\"--rag\"}],\"variableName\":\"rag\"}");
import(/* webpackMode: "eager" */ "/app/packages/apollo/apollo-provider.js");
import(/* webpackMode: "eager" */ "/app/packages/utils/gtm.ts");
import(/* webpackMode: "eager" */ "/app/packages/utils/ReactQueryContext.tsx");
import(/* webpackMode: "eager" */ "/app/packages/utils/searchResults.ts");
import(/* webpackMode: "eager" */ "/app/packages/utils/useApi.ts");
import(/* webpackMode: "eager" */ "/app/packages/utils/UseOutsideClick.tsx")