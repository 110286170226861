"use client"

import styled from "@emotion/styled"
import { Button } from "@mui/material"
import Link from "next/link"
import { useRef, useState } from "react"
import { useInView } from "react-intersection-observer"
import colors from "theme/colors"
import {
  Container,
  NewText,
  transitionDelayTime,
  TransitionUp,
  useInViewConfig,
  useTransitionUpStyles,
} from "ui"
import VideoPauseIcon from "ui/icons/video/VideoPauseIcon"
import VideoPlayIcon from "ui/icons/video/VideoPlayIcon"
import ImageWithAlt from "ui/ImageWithAlt"
import { delayTime, styleObjectToCss } from "ui/transitions/utils"
import { extractImage, extractProperty } from "utils"

const StyledContainer = styled.div`
  padding: 56px 0px;
  ${(props) => props.theme.breakpoints.up("md")} {
    padding: 112px 0px;
  }
`
const StyledTextContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px;

  ${(props) => props.theme.breakpoints.up("md")} {
    grid-template-columns: 1fr 1fr;
    gap: 80px;
  }
`

const StyledDescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  p {
    margin-top: 0px;
  }
`

const StyledButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  width: 100%;

  ${(props) => props.theme.breakpoints.up("md")} {
    flex-direction: row;
  }
`

const StyledImageContainer = styled.div`
  position: relative;
  height: 420px;
  margin-top: 40px;
  border-radius: 12px;
  overflow: hidden;
  ${(props) => props.theme.breakpoints.up("md")} {
    height: 738px;
    margin-top: 80px;
  }
`

const StyledButton = styled(Button)`
  position: absolute;
  bottom: 0px;
  right: 0px;
  z-index: 10;
`

type Props = {
  data: any
}

function CallToAction({ data }: Props) {
  const title = extractProperty(data, "title")
  const content = extractProperty(data, "content")
  const button = extractProperty(data, "button")
  const imageOrVideo = extractImage(data, "imageOrVideo")
  const videoRef = useRef<HTMLVideoElement>(null)
  const videoRefMobile = useRef<HTMLVideoElement>(null)
  const [isPlaying, setIsPlaying] = useState(false)

  const handlePlay = () => {
    if (videoRef.current) {
      videoRef.current.play()
      setIsPlaying(true)
    }
    if (videoRefMobile.current) {
      videoRefMobile.current.play()
      setIsPlaying(true)
    }
  }

  const handlePause = () => {
    if (videoRef.current) {
      videoRef.current.pause()
      setIsPlaying(false)
    }
    if (videoRefMobile.current) {
      videoRefMobile.current.pause()
      setIsPlaying(false)
    }
  }

  const { ref, inView } = useInView(useInViewConfig)
  const imageTransitionStyles = useTransitionUpStyles(inView)
  const descriptionTransitionStyles = useTransitionUpStyles(inView, delayTime)

  return (
    <Container>
      <StyledContainer>
        <StyledTextContainer ref={ref}>
          <NewText
            cssString={styleObjectToCss(imageTransitionStyles)}
            component={"h2"}
            desktopFontSize={40}
            desktopLineHeight={"44px"}
            desktopFontWeight={400}
            mobileFontSize={32}
            mobileLineHeight={"46px"}
            mobileFontWeight={400}
            color={colors.black}
          >
            {title}
          </NewText>
          <StyledDescriptionContainer>
            <NewText
              cssString={styleObjectToCss(descriptionTransitionStyles)}
              marginTop="24px"
              component={"p"}
              desktopFontSize={14}
              desktopLineHeight={"20px"}
              desktopFontWeight={400}
              mobileFontSize={12}
              mobileLineHeight={"17px"}
              color={colors.black}
              dangerouslySetInnerHTML={{ __html: content }}
            />
            <TransitionUp $inView={inView} $delay={transitionDelayTime * 6}>
              <StyledButtonContainer>
                <Link href={button?.[0]?.url || "#"}>
                  <Button
                    style={{
                      background: "#A2C900",
                      color: colors.black,
                      textTransform: "none",
                      width: "100%",
                    }}
                    variant="contained"
                    size="large"
                  >
                    {button?.[0]?.name}
                  </Button>
                </Link>
              </StyledButtonContainer>
            </TransitionUp>
          </StyledDescriptionContainer>
        </StyledTextContainer>
        <StyledImageContainer>
          {/* If imageOrVideo.url ends with mp4 render video else next image */}
          {imageOrVideo?.url?.endsWith("mp4") ? (
            <video
              ref={videoRefMobile}
              muted
              loop
              playsInline
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                ...imageTransitionStyles,
              }}
            >
              <source src={imageOrVideo.url} type="video/mp4" />
            </video>
          ) : (
            <ImageWithAlt image={imageOrVideo} fill style={{ ...imageTransitionStyles }} />
          )}
          <StyledButton onClick={isPlaying ? handlePause : handlePlay}>
            {isPlaying ? <VideoPauseIcon /> : <VideoPlayIcon />}
          </StyledButton>
        </StyledImageContainer>
      </StyledContainer>
    </Container>
  )
}

export default CallToAction
